var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      !_vm.showImport
        ? _c("div", [
            _c(
              "div",
              { staticClass: "box-head" },
              _vm._l(_vm.tabList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "box-head-list",
                    class: _vm.active === item.key ? "active" : "",
                    on: {
                      click: function ($event) {
                        _vm.active = item.key
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "data" }, [
                      _vm._v(_vm._s(item.num)),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              [
                _c("archivesTable", {
                  ref: "table",
                  attrs: { "tab-key": _vm.active },
                  on: {
                    getImportCallback: _vm.getImportCallback,
                    callBackBatch: _vm.callBackBatch,
                  },
                }),
              ],
              1
            ),
          ])
        : _c("ExcelImport", {
            attrs: {
              title: "导入参保档案",
              "import-template": "socialSecurityArchives",
            },
            on: { goBack: _vm.toggleValue },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }