var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "appForm",
        {
          ref: "appForm",
          attrs: {
            "to-list": _vm.toList,
            "screen-roster-list":
              _vm.tabKey === "2"
                ? _vm.normalSelectionList
                : _vm.tabKey === "3"
                ? _vm.toStopped
                : _vm.abnormalSelectionList,
            width: "400",
          },
          on: {
            getSearchCallback: _vm.getSearchCallback,
            screenRosterParams: _vm.screenRosterParams,
            exportFileList: _vm.exportFileList,
          },
        },
        [
          _c("template", { slot: "customButton" }, [
            _c(
              "div",
              { staticClass: "custom_content" },
              [
                _vm.tabKey === "1" || _vm.tabKey === "3"
                  ? _c(
                      "a-button",
                      {
                        staticClass: "custom-button",
                        attrs: { prefix: "piliangdaoru" },
                        on: { click: _vm.getBatchShutdown },
                      },
                      [_vm._v(" 批量停保")]
                    )
                  : _vm._e(),
                _vm.tabKey === "1"
                  ? _c(
                      "a-button",
                      {
                        staticClass: "custom-button",
                        attrs: { prefix: "piliangdaoru" },
                        on: { click: _vm.getBatchAdjustment },
                      },
                      [_vm._v(" 批量调整 ")]
                    )
                  : _vm._e(),
                _vm.tabKey === "1"
                  ? _c(
                      "a-button",
                      {
                        staticClass: "custom-button",
                        attrs: { prefix: "piliangdaoru" },
                        on: { click: _vm.getImport },
                      },
                      [_vm._v("导入在缴人员")]
                    )
                  : _vm._e(),
                _vm.tabKey === "1"
                  ? _c(
                      "a-button",
                      {
                        staticClass: "custom-button",
                        attrs: { prefix: "daochu" },
                        on: { click: _vm.getCardinalityTable },
                      },
                      [_vm._v(" 导出基数表 ")]
                    )
                  : _vm._e(),
                _vm.tabKey === "2"
                  ? _c(
                      "a-button",
                      {
                        staticClass: "custom-button",
                        attrs: { prefix: "piliangdaoru" },
                        on: { click: _vm.getBatchInsure },
                      },
                      [_vm._v(" 批量投保 ")]
                    )
                  : _vm._e(),
                _vm.tabKey === "1"
                  ? _c(
                      "Alert",
                      {
                        staticClass: "alert_message",
                        attrs: {
                          type: "warning",
                          banner: true,
                          "show-icon": true,
                        },
                      },
                      [
                        _c("template", { slot: "content" }, [
                          _c("span", [
                            _vm._v(
                              "提示：人员的社保、医保、公积金只要有一项在缴，即为正常在缴人员"
                            ),
                          ]),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.tabKey === "2" && Number(_vm.ignoreCount) > 0
                  ? _c(
                      "Alert",
                      {
                        staticClass: "alert_message",
                        attrs: {
                          type: "warning",
                          banner: true,
                          "show-icon": true,
                        },
                      },
                      [
                        _c("template", { slot: "content" }, [
                          _c("span", [
                            _vm._v(
                              " 提示：有" +
                                _vm._s(_vm.ignoreCount) +
                                "人无需在本单位投保，前往"
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "to_be_insured",
                                on: { click: _vm.openIgnoreList },
                              },
                              [_vm._v("查看 >")]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.tabKey === "4"
                  ? _c(
                      "Alert",
                      {
                        staticClass: "alert_message",
                        attrs: {
                          type: "warning",
                          banner: true,
                          "show-icon": true,
                        },
                      },
                      [
                        _c("template", { slot: "content" }, [
                          _c("span", [
                            _vm._v(
                              "提示：社保、医保、公积金均停止缴纳，即为停保人员"
                            ),
                          ]),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvatar", {
                  attrs: {
                    "table-avater": scope.record.avatar,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                    "table-name": scope.record.staffName,
                    "is-archives": true,
                    "jump-type": "social",
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _vm.tabKey === "1"
                  ? _c(
                      "div",
                      { staticClass: "operation-btn-box" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.getAdjustment(scope.record)
                              },
                            },
                          },
                          [_vm._v(" 调整 ")]
                        ),
                        _c(
                          "a-popover",
                          { attrs: { placement: "bottom", trigger: "hover" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                { staticClass: "more-handle-btn" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "link",
                                        disabled:
                                          scope.record.housingCompanyId &&
                                          scope.record.medicalCompanyId &&
                                          scope.record.socialCompanyId,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getIncreaseInsurance(
                                            scope.record
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 增投保 ")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getStopMaintenance(
                                            scope.record
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 停保 ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "more-handle" }, [
                              _vm._v("···"),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabKey === "2"
                  ? _c("div", { staticClass: "operation-btn-box" }, [
                      _c(
                        "div",
                        { staticClass: "operation-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.insure(scope, false)
                                },
                              },
                            },
                            [_vm._v(" 投保 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.getIgnore(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 无需参保 ")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.tabKey === "3"
                  ? _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.getStopMaintenance(scope.record, true)
                          },
                        },
                      },
                      [_vm._v("停保")]
                    )
                  : _vm._e(),
                _vm.tabKey === "4"
                  ? _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.insure(scope, true)
                          },
                        },
                      },
                      [_vm._v("重新投保")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visibleIgnoreList,
            size: "normal",
            title: "无需参保",
          },
          on: { cancel: _vm.offIgnoreListClose, ok: _vm.offIgnoreList },
        },
        [
          _c(
            "div",
            { staticClass: "visible-content" },
            _vm._l(_vm.ignoreList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "user" },
                [
                  _c(
                    "span",
                    { staticClass: "photo" },
                    [
                      _c("CbAvatar", {
                        attrs: {
                          "on-job-id": item.onJobId,
                          "avater-name": item.staffName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "thename" }, [
                    _vm._v("(" + _vm._s(item.staffNum) + ")"),
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "link",
                        disabled: _vm.insuranceList.includes(item.onJobId),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getTenantBillDetail(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.insuranceList.includes(item.onJobId)
                            ? "等待投保"
                            : "加入待投保"
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
      _c("BatchModal", {
        ref: "BatchModal",
        attrs: { title: _vm.title, "query-type": _vm.queryType },
        on: { batchCallBack: _vm.batchCallBack },
      }),
      _c("BatchShutdown", {
        ref: "BatchShutdown",
        attrs: { "query-type": _vm.queryType },
        on: { callBackBatch: _vm.callBackBatch },
      }),
      _c("BatchAdjustment", {
        ref: "BatchAdjustment",
        attrs: { "query-type": _vm.queryType },
        on: { callBackBatch: _vm.callBackBatch },
      }),
      _c("CardinalityTable", { ref: "CardinalityTable" }),
      _c("Adjustment", {
        ref: "Adjustment",
        on: { callBackBatch: _vm.callBackBatch },
      }),
      _c("IncreaseInsurance", {
        ref: "IncreaseInsurance",
        on: { callBackBatch: _vm.callBackBatch },
      }),
      _c("StopMaintenance", {
        ref: "StopMaintenance",
        on: { callBackBatch: _vm.callBackBatch },
      }),
      _c("Insure", {
        ref: "insure",
        attrs: { "is-again": _vm.isAgain },
        on: { callBackBatch: _vm.callBackBatch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }